<template>
    <div id="SimulationsInfos">

      <page-loader v-if="loading"></page-loader>

      <template v-if="!loading">
        <div class="simulator-step_header" v-if="!isEdit" style="margin-bottom: 30px !important;">
          <h3 class="simulator-step_title">Ajouter une nouvelle simulation</h3>
          <p class="simulator-step_description">Ajouter les dates de la simulation:</p>
        </div>

        <div class="giga-fields" style="width: 75%;">
          <div class="col-lg-6">
            <div class="giga-input_field " v-if="userInfo.clientType === USER_TYPE.B2B2B">
              <label class="input-field_label">{{$t('Desired_return_date')}}</label>
              <div class="input-field_area">
                <date-picker
                    id="Desired_return_date"
                    :disable="isVIEWER"
                    v-model="$v.simulation.desiredReturnDate.$model"
                    format='D MMMM YYYY'
                    valueType="YYYY-MM-DD"
                    :clearable="false"
                    :editable = "false"
                >
                  <template slot="icon-calendar">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 3H4C2.34315 3 1 4.34315 1 6V20C1 21.6569 2.34315 23 4 23H20C21.6569 23 23 21.6569 23 20V6C23 4.34315 21.6569 3 20 3Z" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M1 9H23" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M12 5V1" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M6 5V1" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M18 5V1" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M5 14H7" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M11 14H13" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M17 14H19" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M5 18H7" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M11 18H13" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M17 18H19" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </template>
                </date-picker>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="giga-input_field " v-if="userInfo.clientType === USER_TYPE.B2B2B">
              <label class="input-field_label">{{$t('Date_of_submission_of_the_technical_offer')}}</label>
              <div class="input-field_area">
                <date-picker
                    id="Date_of_submission_of_the_technical_offer"
                    :disable="isVIEWER"
                    v-model="$v.simulation.submissionTechnicalOfferDate.$model"
                    format='D MMMM YYYY'
                    valueType="YYYY-MM-DD"
                    :clearable="false"
                    :editable = "false"
                >
                  <template slot="icon-calendar">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 3H4C2.34315 3 1 4.34315 1 6V20C1 21.6569 2.34315 23 4 23H20C21.6569 23 23 21.6569 23 20V6C23 4.34315 21.6569 3 20 3Z" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M1 9H23" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M12 5V1" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M6 5V1" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M18 5V1" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M5 14H7" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M11 14H13" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M17 14H19" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M5 18H7" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M11 18H13" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M17 18H19" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </template>
                </date-picker>
              </div>
            </div>
          </div>
            <div class="col-lg-6">
              <div class="giga-input_field " v-if="userInfo.clientType === USER_TYPE.B2B2B">
                <label class="input-field_label">{{$t('Date_of_contracting_of_the_project')}}</label>
                <div class="input-field_area">
                  <date-picker
                      id="Date_of_contracting_of_the_project"
                      :disable="isVIEWER"
                      v-model="$v.simulation.contractingProjectDate.$model"
                      format='D MMMM YYYY'
                      valueType="YYYY-MM-DD"
                      :clearable="false"
                      :editable = "false"
                  >
                    <template slot="icon-calendar">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20 3H4C2.34315 3 1 4.34315 1 6V20C1 21.6569 2.34315 23 4 23H20C21.6569 23 23 21.6569 23 20V6C23 4.34315 21.6569 3 20 3Z" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M1 9H23" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12 5V1" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M6 5V1" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M18 5V1" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M5 14H7" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M11 14H13" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M17 14H19" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M5 18H7" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M11 18H13" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M17 18H19" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </template>
                  </date-picker>
                </div>
              </div>
            </div>
          <div class="col-lg-6" >
            <div class="giga-input_field " v-if="userInfo.clientType === USER_TYPE.B2B2B">
              <label class="input-field_label">{{$t('Work_start_date')}}</label>
              <div class="input-field_area">
                <date-picker
                    id="Work_start_date"
                    :disable="isVIEWER"
                    v-model="$v.simulation.workStartDate.$model"
                    format='D MMMM YYYY'
                    valueType="YYYY-MM-DD"
                    :clearable="false"
                    :editable = "false"
                >
                  <template slot="icon-calendar">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 3H4C2.34315 3 1 4.34315 1 6V20C1 21.6569 2.34315 23 4 23H20C21.6569 23 23 21.6569 23 20V6C23 4.34315 21.6569 3 20 3Z" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M1 9H23" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M12 5V1" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M6 5V1" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M18 5V1" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M5 14H7" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M11 14H13" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M17 14H19" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M5 18H7" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M11 18H13" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M17 18H19" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </template>
                </date-picker>
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="giga-input_field ">
              <label class="input-field_label">{{$t('estimated_end_date')}}</label>
              <div class="input-field_area">
                <date-picker
                    id="estimated_end_date"
                    :disable="isVIEWER"
                    v-model="$v.simulation.deliveryDate.$model"
                    format='D MMMM YYYY'
                    valueType="YYYY-MM-DD"
                    :clearable="false"
                    :editable = "false"
                >
                  <template slot="icon-calendar">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 3H4C2.34315 3 1 4.34315 1 6V20C1 21.6569 2.34315 23 4 23H20C21.6569 23 23 21.6569 23 20V6C23 4.34315 21.6569 3 20 3Z" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M1 9H23" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M12 5V1" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M6 5V1" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M18 5V1" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M5 14H7" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M11 14H13" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M17 14H19" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M5 18H7" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M11 18H13" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M17 18H19" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </template>
                </date-picker>
              </div>
            </div>
          </div>

        </div>

        <SimulationInfoModal></SimulationInfoModal>
      </template>

        <!-- {{simulation}} -->
        <!-- {{simulationForm}} -->



    </div>
</template>

<script>

import {mapActions, mapGetters, mapMutations} from 'vuex'
import {required, requiredIf} from 'vuelidate/lib/validators';
import SimulationInfoModal from '@/views/Simulations/SimulationInfoModal';
import eventBus from "@/shared/event.bus";
import { pick } from "lodash";
import PageLoader from '../../../components/static/pageLoader.vue'
import {regex} from "vuelidate/lib/validators/common";
import {agenceService} from "@/views/service/agence.service";
import {userService} from "@/views/service/user.service";
import {simulationService} from "@/views/Simulations/services";
import {helpers} from "@/shared/helper";
import {constants} from "@/shared/constants";

export default {
    name: "SimulationPlanning",
    components: {SimulationInfoModal, PageLoader},
    validations: {
      simulation: {
        deliveryDate: {
          required: requiredIf(function() {
            return this.fieldsRequiredB2B2B;
          })
        },
        desiredReturnDate: {
          required: requiredIf(function() {
            return this.fieldsRequiredB2B2B;
          })
        },
        submissionTechnicalOfferDate: {
          required: requiredIf(function() {
            return this.fieldsRequiredB2B2B;
          })
        },
        contractingProjectDate: {
          required: requiredIf(function() {
            return this.fieldsRequiredB2B2B;
          })
        },
        workStartDate: {
          required: requiredIf(function() {
            return this.fieldsRequiredB2B2B;
          })
        }
      }
    },
  async mounted() {
      if (this.simulation.id) {
        const invalid = this.$v.$invalid ;
        eventBus.$emit("invalid-form", invalid);
      } else {
      eventBus.$emit("invalid-form", true);
        if(this.userInfo.clientType === this.USER_TYPE.B2B2B){
          this.setSimulation({
            name: '',
            deliveryDate: null,
            desiredReturnDate: null,
            submissionTechnicalOfferDate: null,
            contractingProjectDate: null,
            workStartDate: null,
            siret: '',
            comment: '',
            agence: null,
            user: null
          });
        }else {
          this.setSimulation({
            name: '',
            deliveryDate: null,
            comment: ''
          });
        }
      }

  },
  beforeDestroy() {
    const payload = {
      id: this.simulation.id,
      deliveryDate: this.$v.simulation.deliveryDate.$model,
      desiredReturnDate: this.$v.simulation.desiredReturnDate.$model,
      submissionTechnicalOfferDate: this.$v.simulation.submissionTechnicalOfferDate.$model,
      contractingProjectDate: this.$v.simulation.contractingProjectDate.$model,
      workStartDate: this.$v.simulation.workStartDate.$model
    };
    eventBus.$emit("save-simulation", payload);
  },
    computed:{
          ...mapGetters('simulation', {simulation : 'getSimulation', isEdit: 'isEdit', simulationType: "getSimulationType", users: "getUsers"}),
          ...mapGetters("loader", { loading: "getLoading" }),
          ...mapGetters("account", { userInfo: "getCurrentUser" }),
      fieldsRequiredB2B2B() {
        return this.userInfo.clientType === this.USER_TYPE.B2B2B;
      },
      isVIEWER(){
        return helpers.isVIEWER();
      }
      },
      data(){
          return{
            agences: [],
            siretIsAttachedToCompany: true,
            USER_TYPE: constants.USER_TYPE,
            ROLE_TYPE: constants.ROLE_TYPE,
          }
      },
      methods: {
        ...mapMutations('simulation', ["setSimulation", "setUsersAgence"]),
        ...mapActions('simulation', ['fetchUsersByAgency']),


},
    watch: {
      '$v.$invalid' : function () {
        const invalid = this.$v.$invalid;
        eventBus.$emit("invalid-form", invalid);
      },
    }
}
</script>
<style>
.field-hasError {
  box-shadow: 0px 0px 3px 0px #E90E00 !important;

}
.giga-fields .giga-input_field:last-child {
  margin-bottom: 20px;
}
</style>
