<template>
   <component :is="component" ></component>
</template>

<script>

import SimulationType from "./Wizzard/SimulationType.vue";
import SimulationOperations from "./Wizzard/SimulationOperations.vue";
import SimulationInfos from "./Wizzard/SimulationInfos.vue";
import SimulationPlanning from "./Wizzard/SimulationPlanning.vue";
import SimulationDocuments from "./Wizzard/SimulationDocuments.vue";
import FinalStep from "./Wizzard/FinalStep.vue";
import {mapMutations} from "vuex";

export default {
  name: "SimulationWizzard",
  components: {
    SimulationType,
    SimulationOperations,
    SimulationInfos,
    SimulationPlanning,
    SimulationDocuments,
    FinalStep
  },
  props: ['currentStep'],
  computed:{
    component(){
      let mappings = {
        1: 'SimulationType',
        2: 'SimulationInfos',
        3: 'SimulationPlanning',
        4: 'SimulationOperations',
        5: 'SimulationDocuments',
        6: 'FinalStep'
      }
      this.setComponentName(mappings[this.currentStep]);
      return mappings[this.currentStep];
    }
  },
  methods: {
    ...mapMutations('simulation', ['setComponentName'])
  }
}

</script>
